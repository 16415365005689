<template>
  <ControlButton
    v-if="menuData"
    id="accessibility_settings_button"
    ref="accessibilitySettingsButton"
    :inverted="false"
    button-style="button-default"
    :class="`shadow-none outline-offset-[-6px] ${$props.class}`"
    tabbable-display-check-type="none"
    :aria-label="menuData.localization.accessibilitySettingsTitle"
    @click="showAccessibilityDialog()"
  >
    <template #icon>
      <IconPzo name="accessibility" class="text-xl" />
    </template>
  </ControlButton>
  <ModalDialog
    v-if="menuData"
    id="accessibility_dialog"
    v-model="accessibilityDialogVisible"
    class="max-w-3xl w-full h-full sm:h-auto sm:w-auto max-h-screen sm:max-h-[80%]"
    :dialog-title="menuData.localization.accessibilitySettingsTitle"
    :focus-on-close="accessibilitySettingsButton"
    :close-title="globalLocalization.close"
  >
    <div class="space-y-4">
      <div class="text-neutral-2">
        {{ menuData.localization.accessibilitySettingsDescription }}
      </div>
      <InputSpinButton
        id="font_size_spinner"
        :model-value="fontSize"
        :list-spec="{
          list: [
            { value: 3, label: menuData.localization.fontSizeLowerShortLabel },
            {
              value: 0,
              label: menuData.localization.fontSizeDefaultShortLabel,
            },
            { value: 1, label: menuData.localization.fontSizeHigherShortLabel },
            {
              value: 2,
              label: menuData.localization.fontSizeHighestShortLabel,
            },
          ],
          key: 'value',
          value: 'label',
        }"
        mode="normal-horizontal"
        class="grow"
        :label="menuData.localization.fontSizeLabel"
        :hold-mouse-mode-enabled="false"
        @update:model-value="(value) => setFontSize(value as number)"
      />
      <InputSpinButton
        id="contrast_spinner"
        :model-value="contrast"
        :list-spec="{
          list: [
            {
              value: 0,
              label: menuData.localization.contrastDefaultShortLabel,
            },
            {
              value: 1,
              label: menuData.localization.contrastBlackWhiteShortLabel,
            },
            {
              value: 2,
              label: menuData.localization.contrastBlackYellowShortLabel,
            },
            {
              value: 3,
              label: menuData.localization.contrastYellowBlackShortLabel,
            },
          ],
          key: 'value',
          value: 'label',
        }"
        mode="normal-horizontal"
        class="grow"
        :label="menuData.localization.contrastLabel"
        :hold-mouse-mode-enabled="false"
        @update:model-value="(value) => setContrast(value as number)"
      />
    </div>
    <template #buttons>
      <ControlButton
        id="close_accessibility_dialog"
        :inverted="false"
        button-style="panel-button-normal-sm"
        @click="hideAccessibilityDialog()"
      >
        {{ globalLocalization.close }}
      </ControlButton>
    </template>
  </ModalDialog>
</template>
<script setup lang="ts">
import type ControlButtonVue from "common-components/ControlButton.vue";
import type { MenuDataCommon } from "../../types/menuData";

const { setFontSize, setContrast, fontSize, contrast } = useTheme();
interface Props {
  menuData: MenuDataCommon | undefined | null;
  class?: string;
}

const globalLocalization = useGlobalLocalization();

const accessibilityDialogVisible = ref(false);
const accessibilitySettingsButton =
  ref<InstanceType<typeof ControlButtonVue>>();

function showAccessibilityDialog() {
  accessibilityDialogVisible.value = true;
}

function hideAccessibilityDialog() {
  accessibilityDialogVisible.value = false;
}

defineProps<Props>();
</script>
