<template>
  <div
    v-if="consentStatus !== 'acknowledged'"
    id="cookie_bottom_panel"
    class="border-t-1 absolute bottom-0 z-50 w-full border border-x-0 border-b-0 border-neutral-7 bg-neutral-9 px-2 py-1"
  >
    <div
      class="block w-full content-center items-center justify-center p-2 sm:flex"
    >
      <div class="max-w-3xl">
        <span class="p-1 text-xs sm:text-sm">
          {{ localization.privatePolicyText }}
          <ControlLink
            :label="localization.privatePolicy"
            target="_blank"
            href="https://www.assecods.pl/polityka-prywatnosci/"
          ></ControlLink>
        </span>
      </div>

      <ControlButton
        id="accept_privacy_policy"
        button-style="button-normal"
        class="float-right mb-1 mt-2 p-1 sm:mt-0"
        @click="createCookie"
        >{{ localization.agree }}</ControlButton
      >
    </div>
  </div>
  <div v-else></div>
</template>
<script setup lang="ts">
const DAYS_TO_EXPIRATION = 180;
const localization = useGlobalLocalization();

const consentStatus = useCookie<"acknowledged" | null>("consent_status", {
  maxAge: DAYS_TO_EXPIRATION * 24 * 60 * 60,
  sameSite: "lax",
});

function createCookie() {
  consentStatus.value = "acknowledged";
}
</script>
